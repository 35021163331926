import React, { useEffect } from "react";
import MessageBubble from "./MessageBubble";
// import "./messages-panel.css";

const MessagesPanel = ({ messages, currentUser }) => {
  useEffect(() => {
    console.log("Messages Array:", messages);
    console.log("Current User:", currentUser);
  }, [messages, currentUser]);

  if (!messages || messages.length === 0) {
    return <div className="messages-container">No messages to display.</div>;
  }

  return (
    <div className="messages-container">
      {messages.map((msg, index) => {
        const isOwnMessage = msg.sender_id?.user_id === currentUser?.id;
        const avatar = isOwnMessage
          ? msg.sender_id?.profile_url
          : msg.receiver_id?.profile_url;
        const name = isOwnMessage
          ? `${msg.sender_id?.first_name || "You"}`
          : `${msg.receiver_id?.first_name || "Unknown"}`;
        const time = msg.timestamp
          ? new Date(msg.timestamp).toLocaleTimeString([],{ 
            hour: "2-digit",
            minute: "2-digit",
            hour12:false,
          })
          : "";
          

        return (
          <MessageBubble
            key={msg._id || index}
            message={{
              text: msg.message || "",
              avatar: avatar || "",
              name: name || "Unknown",
              time,
            }}
            isOwnMessage={isOwnMessage}
          />
        );
      })}
    </div>
  );
};

export default MessagesPanel;
