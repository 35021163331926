import React, { useEffect, useRef } from "react";
import MessageBubble from "./MessageBubble";

const UserMessagesPanel = ({ messages, currentUser }) => {
  const messagesEndRef = useRef(null); // Reference to the bottom of the messages container

  useEffect(() => {
    console.log("Messages Array:", messages);
    console.log("Current User:", currentUser);
    scrollToBottom(); // Automatically scroll to the bottom when messages change
  }, [messages]);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!messages || messages.length === 0) {
    return <div className="messages-container">No messages to display.</div>;
  }

  return (
    <div className="messages-container">
      {messages.map((msg, index) => {
        const isOwnMessage = msg.sender_id?.user_id === currentUser?.user_id;
        const avatar = isOwnMessage
          ? msg.sender_id?.profile_url
          : msg.receiver_id?.profile_url;
        const name = isOwnMessage
          ? `${msg.sender_id?.first_name || "You"}`
          : `${msg.receiver_id?.first_name || "Unknown"}`;
        const time = msg.timestamp
          ? new Date(msg.timestamp).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
          : "";

        return (
          <MessageBubble
            key={msg._id || index}
            message={{
              text: msg.message || "",
              avatar: avatar || "",
              name: name || "Unknown",
              time,
            }}
            isOwnMessage={isOwnMessage}
          />
        );
      })}

      {/* This empty div is used to scroll to the bottom */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default UserMessagesPanel;
