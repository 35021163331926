import React from "react";
import "./message-bubble.css";

const MessageBubble = ({ message, isOwnMessage }) => {
  return (
    <div
      className={`bubble-wrapper ${
        isOwnMessage ? "align-right" : "align-left"
      }`}
    >
      <div className={`message-bubble ${isOwnMessage ? "own" : "other"}`}>
        <div className="message-header">
          {/* <img src={message.avatar} alt="Avatar" className="avatar" /> */}
          <div className="msgwithtym">
         <div>{message.text && <p className="message-text">{message.text}</p>}</div>
            <div id="timen"><span className="timestamp">{message.time}</span></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBubble;
