import React, { useState, useEffect } from "react";
import ChatList from "./ChatList";
import ChatHeader from "./ChatHeader";
import MessagesPanel from "./MessagesPanel";
import MessageInput from "./MessageInput";
import "./MainMessage.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import HomeHeader from "../HomeHeader";
import BaseUrl from "../../Server/BaseUrl";

const MainMessage = () => {
  const [currentChat, setCurrentChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const sender_user_id = localStorage.getItem("user_id");
  const [receiver_user_id, setReceiverUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  // Fetch messages from the API
  // Get the property data passed from UserDetails component
  const location = useLocation();
  const receivedPropertyDetails = location?.state?.propertyDetails;
  const receivedUserData = location?.state?.userData; // Access userData

  useEffect(() => {
    if (receivedUserData) {
      setUserData(receivedUserData);
    }

    if (receivedPropertyDetails) {
      setPropertyDetails(receivedPropertyDetails); // Store propertyDetails
      if (receivedPropertyDetails.property?.user_id) {
        const defaultChat = {
          id: receivedPropertyDetails.property.user_id,
          firstName: receivedPropertyDetails.first_name,
          lastName: receivedPropertyDetails.last_name,
          profile_url: receivedPropertyDetails.profile_url,
          property_name: receivedPropertyDetails.property.property_name,
        };
        setChats([defaultChat]);
        setCurrentChat(defaultChat);
        setReceiverUserId(receivedPropertyDetails.property.user_id);
      }
    }
  }, [receivedUserData, receivedPropertyDetails]);


  console.log("data from headers chats:- ", userData);

  useEffect(() => {
    if (sender_user_id && receiver_user_id) {
      fetchMessages();
    }
  }, [sender_user_id, receiver_user_id]);
  const fetchMessages = () => {
    axios
      .get(
        `${BaseUrl.BaseUrl}/chat/fetch/${sender_user_id}/${receiver_user_id}`
      )
      .then((response) => {
        setMessages(response.data.messages);
      })
      .catch((error) => console.error("Error fetching messages:", error));
  };

  // console.log("user_id", propertyDetails);

  const handleChatSelect = (chatId) => {
    const selectedChat = chats.find((chat) => chat.id === chatId);
    setCurrentChat(selectedChat);
    setReceiverUserId(chatId); // Update receiver ID based on the selected chat
    fetchMessages();
  };

  const handleSendMessage = (text) => {
    const newMessage = {
      sender_user_id,
      receiver_user_id,
      message: text,
      file_url: "",
    };

    axios
      .post(`${BaseUrl.BaseUrl}/chat/send`, newMessage)
      .then((response) => {
        const savedMessage = response.data.newMessage;
        setMessages((prevMessages) => [...prevMessages, savedMessage]); // Use the API response
      })
      .catch((error) => console.error("Error sending message:", error));
  };

  console.log("send data for sender msg ===>", messages);
  const handleFileSend = (file) => {
    console.log("File received:", file);

    const newMessage = {
      sender_user_id,
      receiver_user_id,
      message: `File: ${file.name}`,
      file_url: "", // Add file handling logic here if needed
    };

    axios
      .post(`${BaseUrl.BaseUrl}/chat/send`, newMessage)
      .then((response) => {
        setMessages([...messages, response.data.message]);
      })
      .catch((error) => console.error("Error sending file message:", error));
  };

  const handleImageSend = (image) => {
    console.log("Image received:", image);

    const newMessage = {
      sender_user_id,
      receiver_user_id,
      message: "Image sent",
      file_url: URL.createObjectURL(image),
    };

    axios
      .post(`${BaseUrl.BaseUrl}/chat/send`, newMessage)
      .then((response) => {
        setMessages([...messages, response.data.message]);
      })
      .catch((error) => console.error("Error sending image message:", error));
  };

  return (
    <div>
      <HomeHeader />
      <div className="main-message-container">
        {/* Chat List (Sidebar) */}
        <div className="chat-list-sidebar">
          <ChatList
            chats={chats}
            onChatSelect={handleChatSelect}
            selectedChatId={currentChat?.id}
          />
        </div>

        {/* Messages Panel */}
        {/* Messages Panel */}
        {currentChat && (
          <div className="messages-panel">
            <ChatHeader user={currentChat} />
            <div className="messages-panel-content">
              <MessagesPanel
                messages={messages}
                currentUser={{ id: sender_user_id, name: "You" }}
              />
            </div>
            <MessageInput
              onSend={handleSendMessage}
              onFileSend={handleFileSend}
              onImageSend={handleImageSend}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainMessage;
