import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomeHeader.css";
import BaseUrl from "../Server/BaseUrl";
import Dropdown from "./property/DropDown";
import { NotificationContext } from "../../contexts/NotificationContext";
import { io } from "socket.io-client"; 
import SkeletonWrapper from "./Skeleton/SkeletonWrapper";
SkeletonWrapper;

const HomeHeader = () => {
  const navigate = useNavigate();
  const [hasProperty, setHasProperty] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userId = localStorage.user_id;
  const { unreadCount, fetchNotifications, setUnreadCount } =
    useContext(NotificationContext);

  const socket = io(BaseUrl.BaseUrl, { transports: ["websocket"] });

  const [loading, setLoading] = useState(true); // Add a loading state
  const [userData, setUserData] = useState();

  // Fetch user profile and check if they have a property
  useEffect(() => {
    const checkUserProperties = async () => {
      if (userId) {
        try {
          const response = await fetch(
            `${BaseUrl.BaseUrl}/user_profile/${userId}`
          );
          const data = await response.json();
          setUserData(data); // Save user data
          setHasProperty(data.user_type === "Host");

          // Set true for Host, false for User
        } catch (error) {
          console.error("Error checking user properties:", error);
        } finally {
          setLoading(false); // Set loading to false after API call completes
        }
      } else {
        setLoading(false); // If no userId, also set loading to false
      }
    };

    checkUserProperties();
  }, [userId]);
  console.log("data from headers ", userData);

  //Fetch notifications on component mount
  useEffect(() => {
    if (userId) {
      fetchNotifications();
    }
  }, [userId, fetchNotifications]);

  // // Function to check if user is blocked
  // const checkIfBlocked = async () => {
  //     try {
  //         const response = await fetch(`${BaseUrl.BaseUrl}/user_profile/${userId}`);
  //         const data = await response.json();
  //         if (data.blocked) {
  //             // If user is blocked, log them out
  //             alert("You have been blocked due to policy violation.");
  //             handleLogout();
  //         }
  //     } catch (error) {
  //         console.error('Error checking user blocked status:', error);
  //     }
  // };

  // // Polling logic
  // useEffect(() => {
  //     if (userId) {
  //         const pollingInterval = setInterval(() => {
  //             checkIfBlocked(); // Check if the user is blocked every 1 second
  //         }, 2000);

  //         // Cleanup polling interval on component unmount
  //         return () => clearInterval(pollingInterval);
  //     }
  // }, [userId]); // Dependency array ensures the effect runs when userId is available

  // Listen for socket events for new notifications
  useEffect(() => {
    socket.on("newHostNotifications", (data) => {
      console.log("New property update received:", data);
      // Increase the unreadCount in context
      setUnreadCount((prevCount) => prevCount + 1); // Update unreadCount based on new data
    });

    // Cleanup socket listener on unmount
    return () => {
      socket.off("newHostNotifications");
      socket.disconnect();
    };
  }, [setUnreadCount]); // Ensure this runs only once when component mounts

  // Handle logout functionality
  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("DateData");
    localStorage.removeItem("bookingData");
    localStorage.removeItem("propertyData");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
    window.location.reload();
    // window.location.replace("/"); // Force reload the page to reset history
    window.history.replaceState(null, "", "/");
  };

  // Handle 'Become Host' click logic
  const handleBecomeHostClick = () => {
    const Hostclick = "Host";
    if (userId) {
      if (hasProperty) {
        navigate(`/Dashboard/${userId}`);
      } else {
        navigate("/Registration");
      }
    } else {
      localStorage.setItem("become_a_host_login", Hostclick);
      navigate("/login");
    }
  };
  // Handle 'Become User' click logic
  const handleBecomeUserClick = () => {
    navigate("/");
  };

  // Toggle the navigation menu for mobile view
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Handle booking click - check if user is logged in
  const handleBookingClick = (event) => {
    if (!userId) {
      event.preventDefault(); // Prevent the default link behavior
      navigate("/login"); // Redirect to login if userId is not present
    }
  };

  // Toggle the dropdown for notifications
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const isActive = (path) =>
    location.pathname === path
      ? { color: "#f26436", borderBottom: "8px solid #f26436" }
      : {};

  // Navigate to Message component programmatically
  const handleMessageClick = () => {
    navigate("/Chats", { state: { userData } }); // Pass userData as state
  };

  return (
    <nav
      style={{ borderBottom: "1px solid #E5E7EB", padding: 20 }}
      className="navbar navbar-expand-lg navbar-white"
    >
      {loading ? (
        <SkeletonWrapper
          containerClass="skeleton-homeheader"
          items={[
            { className: "skeleton-logo" }, // Logo

            { className: "skeleton-menu-item" }, // Menu Item

            { className: "skeleton-dropdown" }, // Dropdown Icon

            { className: "skeleton-button" }, // Button

            //  { className: 'skeleton-become' } // Become Button
          ]}
        />
      ) : (
        <div
          style={{ width: "100%", justifyContent: "space-between" }}
          className="container-fluid main-hd"
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              style={{ width: 50, height: 45 }}
              alt=""
              src={require("../Image/Group1.png")}
            />
            <Link
              to="/"
              style={{ height: "30px", width: "155px", marginTop: 6 }}
            >
              <img
                style={{ height: "28px", width: "165px", marginLeft: 10 }}
                alt="Group"
                src={require("../../assets/name logo.png")}
              />
            </Link>
          </div>
          <button
            style={{ width: "120px" }}
            className="navbar-toggler"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavOpen}
            aria-label="Toggle navigation"
            onClick={toggleNav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul
              className="navbar-nav me-auto mb-2 mb-lg-0"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {hasProperty ? (
                <>
                  <li className="nav-item">
                    <Link
                      to={`/dashboard/${userId}`}
                      style={{
                        ...isActive(`/dashboard/${userId}`),
                        fontSize: 18,
                        fontWeight: "500",
                      }}
                      className="nav-link"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={userId ? `/booking/${userId}` : "/login"}
                      onClick={handleBookingClick}
                      style={{
                        ...isActive(`/booking/${userId}`),
                        fontSize: 18,
                        fontWeight: "500",
                      }}
                      className="nav-link"
                    >
                      Booking History
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`/earning/${userId}`}
                      style={{
                        ...isActive(`/earning/${userId}`),
                        fontSize: 18,
                        fontWeight: "500",
                      }}
                      className="nav-link"
                    >
                      Earnings
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`/mylisting/${userId}`}
                      style={{
                        ...isActive(`/mylisting/${userId}`),
                        fontSize: 18,
                        fontWeight: "500",
                      }}
                      className="nav-link"
                    >
                      My Listings
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link
                      to={userId ? `/booking_user/${userId}` : "/login"}
                      onClick={handleBookingClick}
                      style={{
                        ...isActive(`/booking_user/${userId}`),
                        fontSize: 18,
                        fontWeight: "500",
                      }}
                      className="nav-link"
                    >
                      My Booking
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/EmailSupport"
                      style={{
                        ...isActive("/EmailSupport"),
                        fontSize: 18,
                        fontWeight: "500",
                      }}
                      className="nav-link"
                    >
                      Support
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>

          <div
            className="ahost"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              onClick={handleMessageClick} // Navigate on click
              style={{
                cursor: "pointer",
                marginLeft: "15px",
              }}
            >
              <img src={require("../../assets/sms.png")} alt="Message" />
            </div>

            <div style={{ position: "relative" }}>
              <img
                className={`hind-bs ${unreadCount > 0 ? "bell-animate" : ""}`}
                style={{ marginLeft: 15 }}
                src={require("../../assets/notification.png")}
                alt=""
                onClick={toggleDropdown}
              />
              {unreadCount > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: -5,
                    right: -5,
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {unreadCount}
                </span>
              )}
            </div>

            {isDropdownOpen && <Dropdown />}

            {hasProperty ? (
              <div className="Become">
                <button
                  onClick={handleBecomeUserClick}
                  style={{
                    marginLeft: 10,
                    height: 48,
                    background: "none",
                    border: "2px solid #000",
                    padding: 0,
                    fontWeight: 500,
                    borderRadius: 11,
                    whiteSpace: "nowrap",
                  }}
                  className="me-2 btn host"
                  type="button"
                >
                  Become a User
                </button>
              </div>
            ) : (
              <div className="Become">
                <button
                  onClick={handleBecomeHostClick}
                  style={{
                    marginLeft: 10,
                    height: 48,
                    background: "none",
                    border: "1px solid #000",
                    padding: 5,
                    borderRadius: 11,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                  }}
                  className="me-2 btn host"
                  type="button"
                >
                  Become a Host
                </button>
              </div>
            )}
            {userId ? (
              <button
                onClick={handleLogout}
                style={{
                  marginLeft: 20,
                  height: 48,
                  maxWidth: "10rem",
                  border: "none",
                  borderRadius: 11,
                  color: "white",
                  background:
                    "linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)",
                }}
                className="me-2 btn btn-outline-dark"
                type="button"
              >
                Logout
              </button>
            ) : (
              <div className="Signup">
                <Link style={{ marginLeft: 20 }} to="/usersignup">
                  <button
                    style={{
                      height: 48,
                      width: "9rem",
                      border: "none",
                      borderRadius: 11,
                      color: "white",
                      background:
                        "linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)",
                    }}
                    className="me-2 btn btn-outline-dark"
                    type="submit"
                  >
                    Guest Sign Up
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default HomeHeader;
